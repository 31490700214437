<template>
  <div>
    <section class="error">
      <h1>404</h1>
      <h2>Not Found</h2>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'not-found',
    title: 'Not Found — LXDmanager'
  };
</script>
